// Global
app.global = {
     // Load all global inits here
    init: function(){
        app.global.initAircraftSelection();
        //app.global.loadHeader();
        app.global.loadNav();
        app.global.initSticky();
        app.global.initAirportSearch();
        app.global.initContactForm();
        app.global.initPlayers();
        app.global.initNicelabel();
    },

    initNicelabel: function(){
        window.showReturn = true;
        $('[name="trip-type"]').change(function(){
            if($('[name="trip-type"]:checked').val() == 'Drop-off Only'){
                $('.return-date-block').fadeOut(400);
            }else{
                $('.return-date-block').fadeIn(400);
            }
            
        });
        $('input').nicelabel();
    },

    initPlayers: function(){ 
        $('.player iframe').each(function() {
            let target = this;
            this.dataset.ratio = $(target).attr('height') / $(target).attr('width');
            app.global.sizePlayers(target);

            $(window).on('resize', function () {
                app.global.sizePlayers(target);
            });
        });
    },

    sizePlayers: function(target){ 
        let calculatedHeight = $(target).width() * target.dataset.ratio;
        $(target).height(calculatedHeight);
    },

    loadNav: function(){ 
        //close menu if open       
        $(".mobile-menu-close").click(function(e){
            if($("#main-nav-list").hasClass('active')){
                $("#main-nav-list").removeClass('active');
            }
        });
        //show menu if closed       
        $(".mobile-menu-open").click(function(e){
            if(!$("#main-nav-list").hasClass('active')){
                $("#main-nav-list").addClass('active');
            }
        });
        //add animation to ancor items if they are on the page     
        $("#main-nav-list a, button, .button").click(function(e){
            let anchor = $(this).attr('href').split('#')[1];

            if($('#'+anchor).length > 0){
                e.preventDefault();
                $('#main-nav-list.active').removeClass('active');
                $("html,body").animate({scrollTop:$('#'+anchor).offset().top-$('#main-nav').outerHeight(true)},400);
            }
        });
        $('.gallery-section').slick();
    },

    loadLightbox: function(){ 
        lightbox.option({
            'resizeDuration': 100,
            'wrapAround': true
          })
    },

    loadHeader: function(){ 
        console.log("loadHeader()");
    },

    initSticky: function(){ 
        var setHeight = 1;

        $(window).scroll(function(){
            if($(window).scrollTop() > (setHeight) && !$("#main-nav").hasClass('sticky')){
                $("#main-nav").addClass('sticky');
            }else if($(window).scrollTop() <= (setHeight) && $("#main-nav").hasClass('sticky')){
                $("#main-nav").removeClass('sticky');
            }
        });

        $(window).on('resize', function () {
            setHeight = $("#main-nav").height();
            $("#nav-space-holder").height(setHeight);
        });
        
    },

    initStickyLoad: function(){ 
        setHeight = $("#main-nav").height();
        $("#nav-space-holder").height(setHeight);
    },

    initAirportSearch: function(){
        $(".destSearch").on('input focus',function(e){
            $($(this).attr('data-target')).css("display","block"); 
            app.global.searchAirportResult(e,this);    
        });
        $(".destSearch").on('blur',function(e){
            let target = $(this).attr('data-target');      
            setTimeout(() => { 
                $(target).css("display","none"); 
            }, 150);  
        });
        $(".destSearch").on('focus',function(e){
            $($(this).attr('data-target')).css("display","block");   
        });
    },

    searchAirportResult:function(evt,targ){
        var target = $(targ).attr('data-target');
        app.global.setAirportNote('Loading...',target);

        if($(targ).val().length >= 3){
            $.ajax({
                method: "GET",
                url: "/airports/search/"+encodeURIComponent($(targ).val()),
            })
            .done(function(result) {
                app.global.setAirportResult(result,target);
            })
            .fail(function() {
                app.global.setAirportResult(result,target);
            });
        }else{
            app.global.setAirportNote('Type 3 letters or more...',target);
        }
    },

    quoteInputsValid:function(){
        let isValid = true;

        $('#quote .error').remove();

        if($('[name="departure_airport_id"]').val() == ''){
            $('[name="departure_airport_id"]').after("<small class='error'>Please select a departure location</small>");
            isValid = false;
        }

        if($('[name="arrival_airport_id"]').val() == ''){
            $('[name="arrival_airport_id"]').after("<small class='error'>Please select an arrival location</small>");
            isValid = false;
        }

        if($('[name="departure_date"]').val() == ''){
            $('[name="departure_date"]').after("<small class='error'>Please select an departure date</small>");
            isValid = false;
        }

        if($('[name="return_date"]').val() == '' && $('[name="trip-type"]:checked').val() == 'Return'){
            $('[name="return_date"]').after("<small class='error'>Please select an arrival date</small>");
            isValid = false;
        }

        if($('[name="pax"]').val() == '' || !$.isNumeric($('[name="pax"]').val())){
            $('[name="pax"]').after("<small class='error'>Please enter the number of passangers</small>");
            isValid = false;
        }

        if(!isValid){
            $("html,body").animate({scrollTop:$('#quote .error').first().parent().offset().top-$('#main-nav').outerHeight(true)-20},400);
        }
        
        return isValid;
    },

    setAirportResult:function(result,target){
        $(target).html("<ul>" + result + "</ul>");
    },

    setAirportNote:function(noteStr,target){
        $(target).html("<ul><li><small>" + noteStr + "</small></li></ul>");
    },

    setSelectedAirport:function(target){
        //let inputString = "("+$(target).data("iata-code")+") " + $(target).data("airport-name");
        let inputString = $(target).data("airport-name");
        $(target).closest(".input-container").find(".destSearch").val(inputString);
        $(target).closest(".input-container").find(".airportId").val($(target).data("airport-id"));
    },

    initAircraftSelection:function(target){
        $("form[name='getAQuote']").submit(function(e){
            e.preventDefault();
            if(app.global.quoteInputsValid()){
                app.global.openAircraftSelection(this);
            }
        });
        $(".QuoteCloseButton").click(function(e){
            e.preventDefault();
            $(".quote-overlay").fadeOut(400);
        });       
    },

    openAircraftSelection:function(form){
        let formData = $(form).serialize();
        let departureId = $('[name="departure_airport_id"]').val();
        let arrivalId = $('[name="arrival_airport_id"]').val();

        $.ajax({
            method: "GET",
            url: "/aircraft/?"+formData,
        })
        .done(function(result) {
            $("#aircraftSelection").html(result);
            $(".quote-overlay").fadeIn(400); 
        })
        .fail(function() {
            $("#aircraftSelection").html("We have encountered and error. Please check your connection and try again.");
            $(".quote-overlay").fadeIn(400); 
        });
              
    },

    prefillContactForm:function(){
        let scrolPos = $("#homepage-CONTACT").offset().top - ($("#main-nav").outerHeight(true) * 0.8);

        let messageValue =  "Hi Imagine Aviation \r\nPlease can I get an official quote on: \r\n \r\n";
            messageValue += 'Departure Airport: ' + $("#quote [name='departure_airport']").val() + "\r\n";
            messageValue += 'Arrival Airport: ' + $("#quote [name='arrival_airport']").val() + "\r\n";
            messageValue += 'Trip Type: ' + $("#quote [name='trip-type']:checked").val() + "\r\n";
            messageValue += 'Departure Date: ' + $("#quote [name='departure_date']").val() + "\r\n";
            if($('[name="trip-type"]:checked').val() == 'Drop-off & Pick-Up'){
                messageValue += 'Return Date: ' + $("#quote [name='return_date']").val() + "\r\n";
            } 
            messageValue += 'Number of Passengers: ' + $("#quote [name='pax']").val() + "\r\n \r\n";
            messageValue += 'Additional Details:' + "\r\n";

        $("#homepage-CONTACT [name='message']").val(messageValue);
        $(".quote-overlay").fadeOut(400);
        $("html, body").animate({ scrollTop: scrolPos });
    },

    clearContactForm:function(){
        $('#homepage-CONTACT [name="first_name"]').val('');
        $('#homepage-CONTACT [name="last_name"]').val('');
        $('#homepage-CONTACT [name="email_address"]').val('');
        $('#homepage-CONTACT [name="phone_number"]').val('');
        $('#homepage-CONTACT [name="message"]').val('');
        $(".contact-overlay").fadeOut(400);
    },

    initContactForm:function(target){
        $("form[name='contact_form']").submit(function(e){
            e.preventDefault();
            if(app.global.contactFormIsValid()){
                app.global.sendContactForm(this);
            }
        });     
    },

    sendContactForm:function(form){
        let formData = $(form).serialize();

        $(".contact-overlay").fadeIn(400);
        
        $.ajax({
            method: "GET",
            url: "/contact/?"+formData,
        })
        .done(function(result) {
            $("#homepage-CONTACT .loading-anim").fadeOut(400);
            $("#contactResponse").fadeIn(400);
        })
        .fail(function() {
            console.log('fail');
        });
    },

    contactFormIsValid:function(){
        let isValid = true;

        $('#homepage-CONTACT .error').remove();

        if($('[name="first_name"]').val() == ''){
            $('[name="first_name"]').after("<small class='error'>Please enter your first name</small>");
            isValid = false;
        }

        if($('[name="last_name"]').val() == ''){
            $('[name="last_name"]').after("<small class='error'>Please enter your last name</small>");
            isValid = false;
        }

        if($('[name="email_address"]').val() == '' || !app.global.isEmail($('[name="email_address"]').val())){
            $('[name="email_address"]').after("<small class='error'>Please enter a valid email address</small>");
            isValid = false;
        }

        if($('[name="phone_number"]').val() == ''){
            $('[name="phone_number"]').after("<small class='error'>Please enter a valid contact number</small>");
            isValid = false;
        }

        if($('[name="message"]').val() == ''){
            $('[name="message"]').after("<small class='error'>Please enter the number of passangers</small>");
            isValid = false;
        }

        if(!isValid){
            $("html,body").animate({scrollTop:$('#homepage-CONTACT .error').first().parent().offset().top-$('#main-nav').outerHeight(true)-20},400);
        }
        
        return isValid;
    },

    isEmail:function(email){
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    },
}

// Run the global stuff
$(document).ready(function(){
    app.global.init();   
});

$(window).on('load', function () {
    app.global.initStickyLoad();
});